import { BaseService } from './base-service';
import { GetUserListRequestDTO, GetUserListResponseDTO } from '@/core/dto/user';

export class UserService extends BaseService {
	private static instance: UserService;

	constructor(public readonly basePath: string = '/user') {
		super();
	}

	public static getInstance(): UserService {
		if (!UserService.instance) {
			UserService.instance = new UserService();
		}
		return UserService.instance;
	}

	async listOptions(params: GetUserListRequestDTO): Promise<GetUserListResponseDTO> {
		const url = this.basePath + `/`;
		const { data } = await this.getInstance().get<GetUserListResponseDTO>(url, { params });
		return data;
	}
}
