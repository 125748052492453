import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { ActionPlanService } from '@/infra/services/action-plan';

export type GetFileActionPlansDTO = {
	organization_id?: string;
	company_id?: string;
	file_id?: string;
};

const Service = new ActionPlanService();

export const useGetFileActionPlans = (params: GetFileActionPlansDTO) => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_FILE_ACTION_PLANS, params],
		queryFn: () => Service.getFileActionPlans(params),
		enabled: !!params.organization_id && !!params.file_id && !!params.company_id
	});
};
