import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Modal, Button, message } from 'antd';

import { FileAddOutlined } from '@ant-design/icons';
import { Organization } from './Organization';
import { Company } from './Company';

import Api from '@/services/api';
import Can from '@/components/Can';
import { Body, AntIcon, SpinnerLoading } from './styles';
import { setOrganization, setCompany, clearOrganization } from '@/redux/organization/actions';
import { useApplicationContext } from '@/context/application';

export function SelectProject() {
	const dispatch = useDispatch();

	const [current, setCurrent] = useState(0);
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState(null);

	const [organizations, setOrganizations] = useState(null);
	const [companies, setCompanies] = useState(null);

	const [createOrganization, setCreateOrganization] = useState(false);
	const [createCompany, setCreateCompany] = useState(false);

	const { setCompany: setContextCompany, setOrganization: setContextOrganization } = useApplicationContext();

	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const getOrganizations = useCallback(() => {
		Api.get('/organization/')
			.then((res) => setOrganizations(res.data))
			.catch((err) => setErrors(err))
			.finally(() => setLoading(false));
	}, []);

	const getCompaniesCallback = useCallback(() => {
		let url = `/company/user_list/${organization.id}`;
		Api.get(url)
			.then(setLoading(true))
			.then((res) => setCompanies(res.data))
			.catch((err) => setErrors(err))
			.finally(() => setLoading(false));
	}, [organization]);

	useEffect(() => {
		getOrganizations();
	}, []);

	useEffect(() => {
		if (organization) {
			getCompaniesCallback();
		}
	}, [organization]);

	useEffect(() => {
		if (errors) {
			message.error(I18n.get('Failed to fetch information'));
		}
	}, [errors]);

	const selectOrganization = (item) => {
		dispatch(setOrganization(item));
		setContextOrganization(item);
	};

	const selectCompany = (item) => {
		dispatch(setCompany({ organization: organization, company: item }));
		setContextCompany(item);
	};

	const onCreateSuccessOrganization = (organization) => {
		setOrganizations([...organizations, organization]);
	};

	const onCreateSuccessCompany = (company) => {
		setCompanies([...companies, company]);
	};

	const steps = [
		{
			name: 'company',
			content: (
				<Organization
					data={organizations}
					onSelect={selectOrganization}
					visibleCreate={createOrganization}
					onReload={onCreateSuccessOrganization}
					closeCreate={() => setCreateOrganization(false)}
				/>
			)
		},
		{
			name: 'industrial site',
			content: (
				<Company
					data={companies}
					onSelect={selectCompany}
					visibleCreate={createCompany}
					onReload={onCreateSuccessCompany}
					closeCreate={() => setCreateCompany(false)}
				/>
			)
		}
	];

	const title = `Select the desired ${steps[current].name} below`;

	const next = () => {
		setCurrent((current) => current + 1);
	};

	const prev = () => {
		setCurrent((current) => current - 1);
		dispatch(clearOrganization());
	};

	useEffect(() => {
		if (!organization) {
			setVisible(true);
			getOrganizations();
		} else {
			next();
		}
	}, [organization]);

	useEffect(() => {
		if (organization && company) {
			setCurrent(0);
			setVisible(false);
		}

		if (!organization && !company) {
			setCurrent(0);
		}
	}, [organization, company]);

	const Footer = () => (
		<div>
			{current > 0 && <Button onClick={prev}>{I18n.get('Back')}</Button>}
			{current === 0 && organizations?.length > 0 && (
				<Button
					onClick={() => setCreateOrganization(true)}
					disabled={!Can('create', 'organization')}
					icon={<FileAddOutlined />}
					type="primary"
				>
					{I18n.get('Create')}
				</Button>
			)}
			{current === 1 && companies?.length > 0 && (
				<Button
					onClick={() => setCreateCompany(true)}
					disabled={!Can('create', 'company')}
					icon={<FileAddOutlined />}
					type="primary"
				>
					{I18n.get('Create')}
				</Button>
			)}
		</div>
	);

	const Loading = () => <SpinnerLoading indicator={<AntIcon size={38} spin />} />;

	const Suspense = ({ loading, children }) => <Body>{loading ? <Loading /> : children}</Body>;

	return (
		<Modal
			closable={false}
			open={visible}
			title={I18n.get(title)}
			footer={<Footer />}
			bodyStyle={{ maxHeight: '300px', overflowY: 'auto' }}
		>
			<Suspense loading={loading}>{steps[current].content}</Suspense>
		</Modal>
	);
}
