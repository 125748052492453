import React from 'react';
import { Badge, Row, Typography } from 'antd';
import { Description } from './styles';

const { Paragraph } = Typography;

interface TaskProps {
	description: string;
}

export function Task({ description }: TaskProps) {
	return (
		<Row>
			<Badge status="default" />
			<Description lg={18} xl={20}>
				<Paragraph>{description}</Paragraph>
			</Description>
		</Row>
	);
}
