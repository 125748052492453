import { BaseService } from './base-service';
import { Mosaic } from '@/core/domain/mosaic';
import { LineMosaicMapper } from '@/core/mapper/line-mosaic';
import type {
	LineDTO,
	GetLineOptionsParamsDTO,
	FindAllLinesWorstScoreRequestDTO,
	GetLinesRequestDTO
} from '@/core/dto/line';

export class LineService extends BaseService {
	private static instance: LineService;

	constructor(public readonly basePath: string = '/line') {
		super();
	}

	public static getInstance(): LineService {
		if (!LineService.instance) {
			LineService.instance = new LineService();
		}
		return LineService.instance;
	}

	public async listOptions(params: GetLineOptionsParamsDTO): Promise<LineDTO[]> {
		const { organization_id, company_id, sector_id } = params;
		const url = this.basePath + `/${organization_id}/${company_id}/${sector_id}`;
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}

	public async index(params: GetLinesRequestDTO): Promise<LineDTO[]> {
		const { organization_id, company_id, sector_id } = params;
		const url = this.basePath + `/${organization_id}/${company_id}/${sector_id}`;
		const { data } = await this.getInstance().get<LineDTO[]>(url);
		return data;
	}

	public async findAllWorstScore(params: FindAllLinesWorstScoreRequestDTO): Promise<Mosaic> {
		const url = this.basePath + '/worst-score';
		const { data } = await this.getInstance().get(url, { params });
		return new LineMosaicMapper(data).toDomain();
	}
}
