import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { BaseContextOptional } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';

type GetMtmReportResultParams = BaseContextOptional & {
	id?: string;
	locale?: string;
};

export type MtmReportResult = {
	informations: Informations;
	general_results: GeneralResults;
	locale: string;
	operations_files: OperationsFile[];
	id: string;
	comment: string;
	is_completed: boolean;
	consolidated: boolean;
	current_step: number;
	product_operations: Operation[];
	company_id: string;
	workstation_operations: Operation[];
	organization_id: string;
};

type GeneralResults = {
	total_time: string; // Deprecated.
	total_time_operating: string;
	total_time_technical: string;
};

type Informations = {
	line_id: string;
	line_name: string;
	sector_name: string;
	company_name: string;
	organization_name: string;
	report_name: string;
	sector_id: string;
	evaluator_id: string;
	evaluator_name: string;
	analysis_date: Date;
	collection_date: Date;
};

export type OperationsFile = {
	id: string;
	file_id: string;
	name: string;
	duration: string;
	company_id: string;
	original_name: string;
	organization_id: string;
};

type Operation = {
	id: string;
	name: string;
	total_time: string; // Deprecated.
	total_time_operating: string;
	total_time_technical: string;
	operations?: OperationElement[];
};

export type OperationElement = {
	id: string;
	name: string;
	total_time: string;
	total_technical_time: string;
	aggregation_values?: AggregationValues;
	items?: MtmItem[];
};

type AggregationValues = {
	VAA: string;
	SVAA: string;
	NVAA: string;
};

export type MtmItem = {
	id: string;
	time: string;
	code: string;
	quantity: number;
	sequence?: number;
	frequency: number;
	percentage: string;
	coefficient: number;
	description: string;
	type: 'VAA' | 'SVAA' | 'NVAA';
};

const getMtmReportResult = async (params: GetMtmReportResultParams): Promise<MtmReportResult> => {
	const url = `/mtm/report/${params?.id}/result`;
	const { data } = await Api.get<MtmReportResult>(url, { params });
	return data;
};

export const useGetMtmReportResult = (params: GetMtmReportResultParams) => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_MTM_REPORT_RESULT, params],
		queryFn: () => getMtmReportResult(params),
		enabled: !!params.organization_id && !!params.company_id && !!params.id
	});
};
