import React, { ReactNode, createContext, useContext, useState } from 'react';
import { Moment } from 'moment';
import { I18n } from '@aws-amplify/core';
import { useParams } from 'react-router-dom';
import { Context, DataToMap, Option } from './index.types';
import {
	useGetTCM,
	useGetFile,
	useGetLines,
	useGetSectors,
	useGetPostures,
	useGetAddedTime,
	useGetWorkstations,
	useGetWeightRanges,
	useGetSpeedFactors,
	useGetBasicPositions
} from './hooks';

type ChecklistParams = {
	file_id: string;
	organization_id: string;
	company_id: string;
};

interface ChecklistEIProviderProps {
	children: ReactNode;
}

const ChecklistEIContext = createContext<Context>({} as Context);

export const ChecklistEIProvider: React.FC<ChecklistEIProviderProps> = ({ children }) => {
	const { file_id, organization_id, company_id } = useParams<ChecklistParams>();

	const [lineId, setLineId] = useState('');
	const [sectorId, setSectorId] = useState('');
	const [postureId, setPostureId] = useState('');
	const [currentStep, setCurrentStep] = useState(0);
	const [speedFactor, setSpeedFactor] = useState(0);
	const [postureOrder, setPostureOrder] = useState('');
	// const [speedFactorId, setSpeedFactorId] = useState('');
	const [weightRangeId, setWeightRangeId] = useState('');
	// const [workstationId, setWorkstationId] = useState('');
	const [basicPositionId, setBasicPositionId] = useState('');
	const [weightRangeOrder, setWeightRangeOrder] = useState(0);
	const [basicPositionOrder, setBasicPositionOrder] = useState(0);
	// const [collectionDate, setCollectionDate] = useState<Moment | null>(null);

	const {
		data: file,
		isFetching: gettingFile,
		isError: errorGettingFile
	} = useGetFile({ file_id, organization_id, company_id });

	const {
		data: sectors,
		isFetching: gettingSectors,
		isError: errorGettingSectors
	} = useGetSectors({ organization_id, company_id });

	const {
		data: lines,
		isFetching: gettingLines,
		isError: errorGettingLines
	} = useGetLines({ sector_id: sectorId, organization_id, company_id });

	const {
		data: workstations,
		isFetching: gettingWorkstations,
		isError: errorGettingWorkstations
	} = useGetWorkstations({ line_id: lineId, organization_id, company_id });

	const {
		data: basicPositions,
		isFetching: gettingBasicPositions,
		isError: errorGettingBasicPositions
	} = useGetBasicPositions({ organization_id, company_id, currentStep });

	const {
		data: postures,
		isFetching: gettingPostures,
		isError: errorGettingPostures
	} = useGetPostures({
		organization_id,
		company_id,
		basic_position_id: basicPositionId
	});

	const {
		data: weightRanges,
		isFetching: gettingWeightRanges,
		isError: errorGettingWeightRanges
	} = useGetWeightRanges({
		organization_id,
		company_id,
		basic_position_id: basicPositionId,
		posture_id: postureId
	});

	const {
		data: speedFactors,
		isFetching: gettingSpeedFactors,
		isError: errorGettingSpeedFactors
	} = useGetSpeedFactors({
		organization_id,
		company_id,
		currentStep
	});

	const {
		data: tcm,
		isFetching: gettingTCM,
		isError: errorGettingTCM
	} = useGetTCM({
		organization_id,
		company_id,
		basic_position: basicPositionOrder,
		posture: postureOrder,
		weight_range: weightRangeOrder
	});

	const {
		data: addedTime,
		isFetching: gettingAddedTime,
		isError: errorGettingAddedTime
	} = useGetAddedTime({
		organization_id,
		company_id,
		tcm: tcm?.tcm,
		speed_factor: speedFactor,
		basic_position_id: basicPositionId,
		posture_id: postureId,
		weight_range_id: weightRangeId
	});

	function setStep(step: number) {
		setCurrentStep(step);
	}

	function handleSelectSector(id: string) {
		setSectorId(id);
	}

	function handleSelectLine(id: string) {
		setLineId(id);
	}

	function handleSelectWorkstation(id: string) {
		// setWorkstationId(id);
	}

	function handleSelectCollectionDate(date: Moment | null) {
		// setCollectionDate(date);
	}

	function handleSelectBasicPosition(id: string) {
		setBasicPositionId(id);
	}

	function handleSelectPosture(id: string) {
		setPostureId(id);
	}

	function handleSelectWeightRange(id: string) {
		setWeightRangeId(id);
	}

	function handlePostureOrder(order: string) {
		setPostureOrder(order);
	}

	function handleWeightRangeOrder(order: number) {
		setWeightRangeOrder(order);
	}

	function handleBasicPositionOrder(order: number) {
		setBasicPositionOrder(order);
	}

	function handleSelectSpeedFactor(id: string, factor: number) {
		// setSpeedFactorId(id);
		setSpeedFactor(factor);
	}

	function mapDataToDropdown(datas: DataToMap): Option[] {
		return datas
			.filter((data) => data?.id)
			.map((data) => ({
				value: data.id,
				label: I18n.get(data?.description || data.name)
			}));
	}

	function filterOption(option: Option | undefined, input: string): boolean {
		return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	const context: Context = {
		postureId,
		currentStep,
		weightRangeId,
		basicPositionId,
		speedFactorValue: speedFactor,
		file: {
			data: file,
			gettingFile,
			errorGettingFile
		},
		lines: {
			data: lines,
			gettingLines,
			errorGettingLines
		},
		sectors: {
			data: sectors,
			gettingSectors,
			errorGettingSectors
		},
		workstations: {
			data: workstations,
			gettingWorkstations,
			errorGettingWorkstations
		},
		basicPositions: {
			data: basicPositions,
			gettingBasicPositions,
			errorGettingBasicPositions
		},
		postures: {
			data: postures,
			gettingPostures,
			errorGettingPostures
		},
		weightRanges: {
			data: weightRanges.map((weightRange) => ({
				...weightRange,
				description: `${weightRange.name} (${weightRange.description})`
			})),
			gettingWeightRanges,
			errorGettingWeightRanges
		},
		speedFactors: {
			data: speedFactors.map((speedFactor) => ({
				...speedFactor,
				description: `${speedFactor.description} (${speedFactor.value})`
			})),
			gettingSpeedFactors,
			errorGettingSpeedFactors
		},
		tcm: {
			data: tcm,
			gettingTCM,
			errorGettingTCM
		},
		addedTime: {
			data: addedTime,
			gettingAddedTime,
			errorGettingAddedTime
		},
		setStep,
		filterOption,
		handleSelectLine,
		mapDataToDropdown,
		handleSelectSector,
		handlePostureOrder,
		handleSelectPosture,
		handleWeightRangeOrder,
		handleSelectSpeedFactor,
		handleSelectWeightRange,
		handleSelectWorkstation,
		handleBasicPositionOrder,
		handleSelectBasicPosition,
		handleSelectCollectionDate
	};

	return <ChecklistEIContext.Provider value={context}>{children}</ChecklistEIContext.Provider>;
};

export function useChecklistEIContext() {
	const context = useContext(ChecklistEIContext);
	return context;
}
