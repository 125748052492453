import { BaseService } from './base-service';
import { GetChecklistHistoryParams, ChecklistHistoryData } from '@/core/dto/checklist';

export class ChecklistService extends BaseService {
	private static instance: ChecklistService;

	constructor(public readonly basePath: string = '/report/checklist') {
		super();
	}

	public static getInstance(): ChecklistService {
		if (!ChecklistService.instance) {
			ChecklistService.instance = new ChecklistService();
		}
		return ChecklistService.instance;
	}

	public async getChecklistHistory(params: GetChecklistHistoryParams): Promise<ChecklistHistoryData[]> {
		const url = this.basePath + '/checklists-history';
		const { data } = await this.getInstance().get<ChecklistHistoryData[]>(url, { params });
		return data;
	}
}
