import { BaseService } from './base-service';
import {
	GetOriginsRequestDTO,
	GetOriginsResponseDTO,
	CreateActionPlanRequestDTO,
	CreateActionPlanResponseDTO,
	BulkDeleteRequestDTO,
	BulkDeleteResponseDTO,
	BulkUpdateRequestDTO,
	BulkUpdateResponseDTO,
	GetActionPlansRequestDTO,
	GetActionPlansResponseDTO,
	CreateUserPreferenceColumnsRequestDTO,
	CreateUserPreferenceColumnsResponseDTO,
	GetUserPreferenceColumnsRequestDTO,
	GetOriginOptionsResponseDTO,
	UpdateNotificationRequestDTO,
	UpdateNotificationResponseDTO,
	GetCountAllRequestDTO,
	GetCountAllResponseDTO,
	GetActionPlanResponseDTO,
	UpdateActionPlanRequestDTO,
	UpdateActionPlanResponseDTO,
	GetActionPlanRequestDTO,
	CreateTasksRequestDTO,
	CreateTasksResponseDTO,
	DeleteTaskRequestDTO,
	DeleteTaskResponseDTO,
	UpdateTaskRequestDTO,
	UpdateTaskResponseDTO,
	UpdateTaskStatusRequestDTO,
	UpdateTaskStatusResponseDTO,
	CreateEvidenceRequestDTO,
	CreateEvidenceResponseDTO,
	GetEvidencesRequestDTO,
	GetAttachmentDownloadUrlRequestDTO,
	GetTaskAttachmentDownloadUrlRequestDTO,
	DeleteEvidenceResponseDTO,
	DeleteEvidenceRequestDTO,
	UpdateEvidenceRequestDTO,
	UpdateEvidenceResponseDTO,
	GetTasksRequestDTO,
	GetTasksResponseDTO,
	GetAttachmentsRequestDTO,
	GetRelatedReportsRequestDTO,
	GetRelatedReportsResponseDTO,
	UpdateAttachmentRequestDTO,
	UpdateAttachmentResponseDTO,
	DeleteAttachmentRequestDTO,
	DeleteAttachmentResponseDTO,
	CreateAttachmentRequestDTO,
	CreateAttachmentResponseDTO,
	CreateRelatedReportsRequestDTO,
	CreateRelatedReportsResponseDTO,
	GetHistoryRequestDTO,
	GetHistoryResponseDTO,
	GetCommentsRequestDTO,
	GetCommentsResponseDTO,
	UpdateCommentRequestDTO,
	UpdateCommentResponseDTO,
	DeleteCommentRequestDTO,
	DeleteCommentResponseDTO,
	CreateCommentRequestDTO,
	CreateCommentResponseDTO,
	UpdateCardRankRequestDTO,
	UpdateCardRankResponseDTO,
	GetActionPlansCardListRequestDTO,
	GetActionPlansCardListResponseDTO,
	CreateDownloadPdfEditRequestDTO,
	CreateActionPlanFromReportRequestDTO,
	CreateActionPlanFromReportResponseDTO
} from '@/core/dto';

import {
	ActionPlanListDomain,
	ActionPlanCreateUserPreferenceColumnsDomain,
	UploadFileItem,
	ActionPlanHistoryDomain,
	ActionPlanCommentsDomain,
	ActionPlanUpdateCommentsDomain,
	ActionPlanDeleteCommentsDomain,
	ActionPlanCreateCommentsDomain
} from '@/core/domain';

import {
	ActionPlanListMapper,
	ActionPlanCreateUserPreferenceColumnsMapper,
	ActionPlanAttachmentsMapper,
	ActionPlanHistoryMapper,
	ActionPlanCommentsMapper,
	ActionPlanUpdateCommentsMapper,
	ActionPlanDeleteCommentsMapper,
	ActionPlanCreateCommentsMapper
} from '@/core/mappers/action-plans';
import {
	GetFileActionPlansRequestDTO,
	GetFileActionPlansResponseDTO
} from '@/core/dto/action-plans/action-plan-get-file';

export class ActionPlanService extends BaseService {
	private static instance: ActionPlanService;

	constructor(public readonly basePath: string = '/action_plan/v2') {
		super();
	}

	public static getInstance(): ActionPlanService {
		if (!ActionPlanService.instance) {
			ActionPlanService.instance = new ActionPlanService();
		}
		return ActionPlanService.instance;
	}

	public async create(params: CreateActionPlanRequestDTO): Promise<CreateActionPlanResponseDTO> {
		const url = this.basePath + '/';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async getActionPlans(params: GetActionPlansRequestDTO): Promise<ActionPlanListDomain> {
		const url = this.basePath + '/';
		const { data } = await this.getInstance().get<GetActionPlansResponseDTO>(url, { params });
		return new ActionPlanListMapper(data).toDomain();
	}

	public async getOrigins(params: GetOriginsRequestDTO): Promise<GetOriginsResponseDTO> {
		const url = this.basePath + '/origin';
		const { data } = await this.getInstance().get<GetOriginsResponseDTO>(url, { params });
		return data;
	}

	async bulkDelete(params: BulkDeleteRequestDTO): Promise<BulkDeleteResponseDTO> {
		const url = this.basePath + '/';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async bulkUpdate(params: BulkUpdateRequestDTO): Promise<BulkUpdateResponseDTO> {
		const url = this.basePath + '/bulk-update';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async createPreferenceColumns(
		params: CreateUserPreferenceColumnsRequestDTO
	): Promise<ActionPlanCreateUserPreferenceColumnsDomain> {
		const url = this.basePath + '/user-preference/columns';
		const { data } = await this.getInstance().post<CreateUserPreferenceColumnsResponseDTO>(url, params);
		return new ActionPlanCreateUserPreferenceColumnsMapper(data).toDomain();
	}

	async getUserPreferenceColumns(params: GetUserPreferenceColumnsRequestDTO): Promise<string[]> {
		const url = this.basePath + '/user-preference/columns';
		const { data } = await this.getInstance().get<string[]>(url, { params });
		return data;
	}

	async getOriginOptions(): Promise<GetOriginOptionsResponseDTO[]> {
		const url = this.basePath + '/origin/option';
		const { data } = await this.getInstance().get<GetOriginOptionsResponseDTO[]>(url);
		return data;
	}

	async getNotifications(): Promise<string[]> {
		const url = this.basePath + '/user-preference/notification';
		const { data } = await this.getInstance().get<string[]>(url);
		return data;
	}

	async updateNotification(
		params: UpdateNotificationRequestDTO
	): Promise<UpdateNotificationResponseDTO> {
		const url = this.basePath + '/user-preference/notification';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async getCountAll(params: GetCountAllRequestDTO): Promise<GetCountAllResponseDTO> {
		const url = this.basePath + '/count-all';
		const { data } = await this.getInstance().get<GetCountAllResponseDTO>(url, { params });
		return data;
	}

	async getFindOne(params: GetActionPlanRequestDTO): Promise<GetActionPlanResponseDTO> {
		const url = this.basePath + `/${params.id}`;
		const { data } = await this.getInstance().get<GetActionPlanResponseDTO>(url, { params });
		return data;
	}

	async update(params: UpdateActionPlanRequestDTO): Promise<UpdateActionPlanResponseDTO> {
		const url = this.basePath + `/${params.id}`;
		const { data } = await this.getInstance().put<UpdateActionPlanResponseDTO>(url, params);
		return data;
	}

	async getTasks(params: GetTasksRequestDTO): Promise<GetTasksResponseDTO[]> {
		const url = this.basePath + '/task';
		const { data } = await this.getInstance().get<GetTasksResponseDTO[]>(url, { params });
		return data;
	}

	async createTask(params: CreateTasksRequestDTO): Promise<CreateTasksResponseDTO> {
		const url = this.basePath + '/task';
		const { data } = await this.getInstance().post<CreateTasksResponseDTO>(url, params);
		return data;
	}

	async deleteTask(params: DeleteTaskRequestDTO): Promise<DeleteTaskResponseDTO> {
		const url = this.basePath + '/task';
		const { data } = await this.getInstance().delete<DeleteTaskResponseDTO>(url, { params });
		return data;
	}

	async updateTask(params: UpdateTaskRequestDTO): Promise<UpdateTaskResponseDTO> {
		const url = this.basePath + '/task';
		const { data } = await this.getInstance().put<UpdateTaskResponseDTO>(url, params);
		return data;
	}

	async updateTaskStatus(
		params: UpdateTaskStatusRequestDTO
	): Promise<UpdateTaskStatusResponseDTO> {
		const url = this.basePath + '/task/completed';
		const { data } = await this.getInstance().put<UpdateTaskStatusResponseDTO>(url, params);
		return data;
	}

	async createEvidence(params: CreateEvidenceRequestDTO): Promise<CreateEvidenceResponseDTO> {
		const url = this.basePath + '/task/attachment';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async getEvidences(params: GetEvidencesRequestDTO): Promise<UploadFileItem[]> {
		const url = this.basePath + '/task/attachment';
		const { data } = await this.getInstance().get(url, { params });
		return new ActionPlanAttachmentsMapper(data).toDomain();
	}

	async getAttachmentDownloadUrl(params: GetAttachmentDownloadUrlRequestDTO): Promise<string> {
		const url = this.basePath + '/attachment/download';
		const { data } = await this.getInstance().get<string>(url, { params });
		return data;
	}

	async getTaskAttachmentDownloadUrl(params: GetTaskAttachmentDownloadUrlRequestDTO): Promise<string> {
		const url = this.basePath + '/task/attachment/download';
		const { data } = await this.getInstance().get<string>(url, { params });
		return data;
	}

	async deleteEvidence(params: DeleteEvidenceRequestDTO): Promise<DeleteEvidenceResponseDTO> {
		const url = this.basePath + '/task/attachment';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async updateEvidence(params: UpdateEvidenceRequestDTO): Promise<UpdateEvidenceResponseDTO> {
		const url = this.basePath + '/task/attachment';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async getAttachments(params: GetAttachmentsRequestDTO): Promise<UploadFileItem[]> {
		const url = this.basePath + '/attachment';
		const { data } = await this.getInstance().get(url, { params });
		return new ActionPlanAttachmentsMapper(data).toDomain();
	}

	async getRelatedReports(params: GetRelatedReportsRequestDTO): Promise<GetRelatedReportsResponseDTO[]> {
		const url = this.basePath + '/related-report';
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}

	async updateAttachment(params: UpdateAttachmentRequestDTO): Promise<UpdateAttachmentResponseDTO> {
		const url = this.basePath + '/attachment';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async deleteAttachment(params: DeleteAttachmentRequestDTO): Promise<DeleteAttachmentResponseDTO> {
		const url = this.basePath + '/attachment';
		const { data } = await this.getInstance().delete(url, { params });
		return data;
	}

	async createAttachment(params: CreateAttachmentRequestDTO): Promise<CreateAttachmentResponseDTO> {
		const url = this.basePath + '/attachment';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async createRelatedReports(params: CreateRelatedReportsRequestDTO): Promise<CreateRelatedReportsResponseDTO> {
		const url = this.basePath + '/related-report';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async getHistory(params: GetHistoryRequestDTO): Promise<ActionPlanHistoryDomain> {
		const url = this.basePath + '/history';
		const { data } = await this.getInstance().get<GetHistoryResponseDTO>(url, { params });
		return new ActionPlanHistoryMapper(data).toDomain();
	}

	async getComments(params: GetCommentsRequestDTO): Promise<ActionPlanCommentsDomain> {
		const url = this.basePath + '/comment';
		const { data } = await this.getInstance().get<GetCommentsResponseDTO>(url, { params });
		return new ActionPlanCommentsMapper(data).toDomain();
	}

	async updateComments(params: UpdateCommentRequestDTO): Promise<ActionPlanUpdateCommentsDomain> {
		const url = this.basePath + '/comment';
		const { data } = await this.getInstance().put<UpdateCommentResponseDTO>(url, params);
		return new ActionPlanUpdateCommentsMapper(data).toDomain();
	}

	async deleteComments(params: DeleteCommentRequestDTO): Promise<ActionPlanDeleteCommentsDomain> {
		const url = this.basePath + '/comment';
		const { data } = await this.getInstance().delete<DeleteCommentResponseDTO>(url, { params });
		return new ActionPlanDeleteCommentsMapper(data).toDomain();
	}

	async createComments(params: CreateCommentRequestDTO): Promise<ActionPlanCreateCommentsDomain> {
		const url = this.basePath + '/comment';
		const { data } = await this.getInstance().post<CreateCommentResponseDTO>(url, params);
		return new ActionPlanCreateCommentsMapper(data).toDomain();
	}

	async updateCardRank(params: UpdateCardRankRequestDTO): Promise<UpdateCardRankResponseDTO> {
		const url = this.basePath + '/update-rank';
		const { data } = await this.getInstance().put(url, params);
		return data;
	}

	async getFindAllForBoard(params: GetActionPlansCardListRequestDTO): Promise<GetActionPlansCardListResponseDTO> {
		const url = this.basePath + '/board';
		const { data } = await this.getInstance().get<GetActionPlansCardListResponseDTO>(url, { params });
		return data;
	}

	async createDownloadPdfEdit(params: CreateDownloadPdfEditRequestDTO): Promise<string> {
		const url = this.basePath + `/${params.id}/pdf`;
		const { data } = await this.getInstance().post<string>(url, params);
		return data;
	}

	async createFromReport(
		params: CreateActionPlanFromReportRequestDTO
	): Promise<CreateActionPlanFromReportResponseDTO> {
		const url = this.basePath + '/report';
		const { data } = await this.getInstance().post(url, params);
		return data;
	}

	async getFileActionPlans(params: GetFileActionPlansRequestDTO): Promise<GetFileActionPlansResponseDTO[]> {
		const url = this.basePath + `/report/${params.file_id}`;
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}
}
