import { Mapper } from '../base-mapper';
import { GetHistoryResponseDTO, GetHistoryItemDTO } from '@/core/dto';
import { ActionPlanHistory, ActionPlanHistoryDomain } from '@/core/domain';

export class ActionPlanHistoryMapper implements Mapper<ActionPlanHistoryDomain> {
	private readonly response: GetHistoryResponseDTO;

	constructor(response: GetHistoryResponseDTO) {
		this.response = {
			data: Array.isArray(response) ? response : []
		};
	}

	private mapActionPlanItem(item: GetHistoryItemDTO): ActionPlanHistory {
		return new ActionPlanHistory({
			id: item.id,
			action_plan_id: item.action_plan_id,
			type: item.type,
			description: item.description,
			author: item.author,
			created_at: item.created_at ?? null
		});
	}

	toDomain(): ActionPlanHistoryDomain {
		return {
			data: this.response.data.map((item) => this.mapActionPlanItem(item))
		};
	}
}
