import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { CompanyService } from '@/infra/services/company';

interface GetCompaniesParams {
	organization_id?: string;
}

const Service = CompanyService.getInstance();

export const useGetCompanies = (params: GetCompaniesParams) => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_COMPANIES, params],
		queryFn: async () => Service.index(params),
		enabled: !!params.organization_id
	});
};
