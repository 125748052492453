// import Api from '@/services/api';
// import type { AxiosInstance } from 'axios';

// export interface IRead<T> {
// 	findOne(id: string): Promise<T>;
// 	findAll(): Promise<T[]>;
// }

// export interface IWrite<T> {
// 	create(data: T): Promise<T>;
// 	update(data: T): Promise<T>;
// 	destroy(id: string): Promise<void>;
// }

// export abstract class BaseService<T> implements IRead<T>, IWrite<T> {
// 	constructor(private readonly api: AxiosInstance = Api) {}

// 	getInstance(): AxiosInstance {
// 		return this.api;
// 	}

// 	// @ts-ignore
// 	findOne(id: string): Promise<T> {
// 		throw new Error('Method not implemented.');
// 	}

// 	findAll(): Promise<T[]> {
// 		throw new Error('Method not implemented.');
// 	}

// 	create(data: T): Promise<T> {
// 		throw new Error('Method not implemented.');
// 	}

// 	update(data: T): Promise<T> {
// 		throw new Error('Method not implemented.');
// 	}

// 	destroy(id: string): Promise<void> {
// 		throw new Error('Method not implemented.');
// 	}
// }

import Api from '@/services/api';
import type { AxiosInstance } from 'axios';

export class BaseService {
	constructor(private readonly api: AxiosInstance = Api) {}

	getInstance(): AxiosInstance {
		return this.api;
	}
}
