import React from 'react';
import { Row, Col } from 'antd';
import { Title } from '@/components/Typography';
import { ResultCard } from '../../../../components/ResultCard';

interface GeneralResultsProps {
	timeTotalOperation?: string;
	timeTotalTechnical?: string;
}

export function GeneralResults({ timeTotalOperation = '0', timeTotalTechnical = '0' }: Readonly<GeneralResultsProps>) {
	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Title level={4} style={{ color: '#262626' }}>
					General results of the line
				</Title>
			</Col>
			<Col span={22} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
				<Row gutter={[20, 20]}>
					<Col>
						<Title level={5} style={{ color: '#262626' }}>
							Line total time (min)
						</Title>
						<ResultCard value={timeTotalOperation} />
					</Col>
					<Col>
						<Title level={5} style={{ color: '#262626' }}>
							Total technical line time (min)
						</Title>
						<ResultCard value={timeTotalTechnical} />
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
