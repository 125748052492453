import { Mapper } from '../base-mapper';
import { DeleteCommentResponseDTO, DeleteCommentItemDTO } from '@/core/dto';
import { ActionPlanDeleteComments, ActionPlanDeleteCommentsDomain } from '@/core/domain';

export class ActionPlanDeleteCommentsMapper implements Mapper<ActionPlanDeleteCommentsDomain> {
	private readonly response: DeleteCommentResponseDTO;

	constructor(response: DeleteCommentResponseDTO) {
		this.response = {
			status: response.status,
			message: response.message,
			data: Array.isArray(response.data) ? response.data : []
		};
	}

	private mapActionPlanItem(item: DeleteCommentItemDTO): ActionPlanDeleteComments {
		return new ActionPlanDeleteComments({
			id: item.id
		});
	}

	toDomain(): ActionPlanDeleteCommentsDomain {
		return {
			status: this.response.message,
			message: this.response.message,
			data: this.response.data.map((item) => this.mapActionPlanItem(item))
		};
	}
}
