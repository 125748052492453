import { Mapper } from '../base-mapper';
import { ActionPlanComments, ActionPlanCommentsDomain } from '../../domain';
import { GetCommentsResponseDTO, GetCommentsItemDTO } from '../../dto';

export class ActionPlanCommentsMapper implements Mapper<ActionPlanCommentsDomain> {
	private readonly response: GetCommentsResponseDTO;

	constructor(response: GetCommentsResponseDTO) {
		this.response = {
			total: response.total,
			comments: Array.isArray(response.comments) ? response.comments : []
		};
	}

	private mapActionPlanItem(item: GetCommentsItemDTO): ActionPlanComments {
		return new ActionPlanComments({
			id: item.id,
			description: item.description,
			updated_at: item.updated_at ?? null,
			user: {
				id: item.user.id,
				name: item.user.name
			}
		});
	}

	toDomain(): ActionPlanCommentsDomain {
		return {
			total: this.response.total,
			comments: this.response.comments.map((item) => this.mapActionPlanItem(item))
		};
	}
}
