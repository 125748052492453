import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Spin, Button } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import Api from '@/services/api';
import { Charts } from './Charts';
import { Options } from '../Components/Options';
import { FileData } from '../Components/FileData';
import { formatSecondsDuration } from '@/utils/mask';
import { Conclusion } from '../Components/Conclusion';
import { RiskDegreeResult, RiskDegreeError } from './styles';
import { JobInformations } from '../Components/JobInformations';
import { setRiskDegree, setData, prev } from '@/redux/reports/actions';
import { collectionDateParse, fileDurationParse, fileSizeFormat, sectorJSON } from '@/redux/reports/selectors';

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const Container = styled(Row)`
	padding: 25px 10px 10px 10px;
`;

export function Diagnostic() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const { parameters, informations } = useSelector((state) => state.reports.customized);

	const file = useSelector((state) => state.reports.file);
	const sector = useSelector(sectorJSON);
	const fileSize = useSelector(fileSizeFormat);
	const fileDuration = useSelector(fileDurationParse);
	const collectionDate = useSelector(collectionDateParse);

	const data = useSelector((state) => state.reports.data);
	const options = useSelector((state) => state.reports.options);
	const user = useSelector((state) => state.auth.user.user);

	const { companyInfo, workstation } = useSelector(
		(state) => ({
			workstation: state.reports.customized.informations.workstation,
			companyInfo: state.reports.customized.informations.company
		}),
		shallowEqual
	);

	const {
		data: riskDegree,
		error: riskDegreeError,
		isLoading: riskDegreeLoading
	} = useQuery({
		queryKey: ['riskDegree', file.id, parameters],
		queryFn: () =>
			Api.post('/report/risk_degree/workstation', {
				organization_id: file.organization_id,
				company_id: file.company_id,
				file_id: file.id,
				legs: parameters.legs,
				force: parameters.force,
				repetition: parameters.legs
			}).then((res) => res.data)
	});

	const {
		data: bodyParts,
		error: bodyPartsError,
		isLoading: bodyPartsLoading
	} = useQuery({
		queryKey: ['bodyParts', file.id],
		queryFn: () =>
			Api.post('/report/rula/risk_degree/body_parts', {
				organization_id: file.organization_id,
				company_id: file.company_id,
				file_id: file.id
			}).then((res) => res.data)
	});

	useEffect(() => {
		if (riskDegree) {
			dispatch(setRiskDegree(riskDegree));
		}
		if (bodyParts) {
			dispatch(setData(bodyParts));
		}
	}, [riskDegree, bodyParts]);

	const RiskDegreeStatus = ({ score }) => {
		let isResult = { type: '', description: 'Not found' };

		if (score >= 1 && score <= 2) {
			isResult = { type: 'safe', description: 'Acceptable' };
		}

		if (score > 2 && score <= 4) {
			isResult = { type: 'info', description: 'Light' };
		}

		if (score > 4 && score <= 6) {
			isResult = { type: 'warning', description: 'Average' };
		}

		if (score > 6 && score >= 7) {
			isResult = { type: 'danger', description: 'High' };
		}

		return <RiskDegreeResult type={isResult.type}>{I18n.get(isResult.description)}</RiskDegreeResult>;
	};

	const Title = ({ children }) => (
		<Col sm={24} style={{ paddingBottom: '15px' }}>
			<h2>{I18n.get(children)}</h2>
		</Col>
	);

	const ExposureAverage = () => (
		<Col sm={24} style={{ paddingTop: '20px' }}>
			<Row type="flex" align="middle" gutter={[8, 0]} style={{ alignItems: 'center' }}>
				<Col>
					<h2>
						{I18n.get('Average exposure of the workplace')}:{' '}
						{riskDegreeLoading && <Spin indicator={antIcon} />}
					</h2>
				</Col>
				<Col>
					{riskDegree && <RiskDegreeStatus score={riskDegree.score} />}
					{riskDegreeError && <RiskDegreeError>{I18n.get('Failed to fetch data')}</RiskDegreeError>}
				</Col>
			</Row>
		</Col>
	);

	function onPrevious() {
		dispatch(prev('customized'));
	}

	function onSuccessResponse(response) {
		const blob = new Blob([response.data], { type: 'application/pdf' });
		window.open(URL.createObjectURL(blob));
	}

	function onErrorResponse(err) {
		console.log(err, 'err');
	}

	function normalizeData() {
		const sector = JSON.parse(informations.sector);
		const durationFormated = formatSecondsDuration(file.duration);
		const collectionDate = moment(informations.collection_date).format('DD/MM/YYYY');

		return {
			tool: 'rula',
			document_type: 'pdf',
			report: 'score_parts',
			parameters: {
				risk_degree: riskDegree?.score || null,
				data: data,
				sector_name: sector.name,
				configuration: 'Default',
				collection_date: collectionDate,
				company_name: informations.company,
				workstation_name: informations.workstation,
				file: {
					name: file.original_name,
					duration: durationFormated,
					size: file.size
				},
				signature: options.signature.checked && {
					author: user.name
				},
				conclusion: options.conclusion.checked && {
					text: options.conclusion.text
				}
			}
		};
	}

	function onFinish() {
		const url = '/report/document';
		const body = normalizeData();
		const opts = { responseType: 'blob' };

		Api.post(url, body, opts)
			.then(setLoading(true))
			.then(onSuccessResponse)
			.catch(onErrorResponse)
			.finally(() => setLoading(false));
	}

	const Footer = () => (
		<Col sm={24} style={{ paddingTop: '15px' }}>
			<Row justify="end">
				<Col>
					<Button style={{ margin: '0 8px' }} onClick={onPrevious}>
						{I18n.get('Previous')}
					</Button>
				</Col>
				<Col>
					<Button type="primary" loading={loading} onClick={onFinish}>
						{I18n.get('Done')}
					</Button>
				</Col>
			</Row>
		</Col>
	);

	return (
		<Container gutter={[10, 10]}>
			<Title>Analysis results</Title>
			<JobInformations
				rangeRisk="RULA"
				sector={sector?.name}
				company={companyInfo}
				workstation={workstation}
				collectionDate={collectionDate}
			/>
			<FileData originalName={file.original_name} duration={fileDuration} size={fileSize} />
			<ExposureAverage />
			<Charts loading={bodyPartsLoading} err={bodyPartsError} data={bodyParts} />
			<Options />
			<Conclusion />
			<Footer />
		</Container>
	);
}
