import React from 'react';
import { Row, Col } from 'antd';
import { MtmReportResult } from '@/hooks';
import { CollapseResults } from './CollapseResult';
import { DownloadPDFParams } from '@/components/views/MtmReport/types';

interface WorkstationOperationsProps {
	workstation_operations?: MtmReportResult['workstation_operations'];
	isLoadingPDF: boolean;
	onDownloadPDF: (params: DownloadPDFParams) => Promise<void>;
}

export function WorkstationOperations({
	workstation_operations,
	onDownloadPDF,
	isLoadingPDF
}: Readonly<WorkstationOperationsProps>) {
	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col span={22}>
				<Row gutter={[50, 40]} justify="start">
					{workstation_operations?.map(
						({ id, name, operations, total_time_operating, total_time_technical }) => (
							<CollapseResults
								key={id}
								id={id}
								title={name}
								operations={operations}
								isLoadingPDF={isLoadingPDF}
								onDownloadPDF={onDownloadPDF}
								totalTimeOperating={total_time_operating}
								totalTimeTechnical={total_time_technical}
							/>
						)
					)}
				</Row>
			</Col>
		</Row>
	);
}
