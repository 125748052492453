import { BaseService } from './base-service';
import { Mosaic } from '@/core/domain/mosaic';
import { Company } from '@/core/domain/company';
import { CompanyMosaicMapper } from '@/core/mapper/company-mosaic';
import type {
	CompanyDTO,
	CompanyAllDTO,
	CompanyMosaicDTO,
	GetCompaniesRequestDTO,
	GetCompanyOptionsParamsDTO,
	FindAllWorstScoreRequestDTO,
	GetCompanyAllOptionsParamsDTO
} from '@/core/dto/company';

export class CompanyService extends BaseService {
	private static instance: CompanyService;

	constructor(public readonly basePath: string = '/company') {
		super();
	}

	public static getInstance(): CompanyService {
		if (!CompanyService.instance) {
			CompanyService.instance = new CompanyService();
		}
		return CompanyService.instance;
	}

	public async listOptions(params: GetCompanyOptionsParamsDTO): Promise<CompanyDTO[]> {
		const url = this.basePath + `/`;
		const { data } = await this.getInstance().get(url, { params });
		return data;
	}

	public async listAllOptions(params: GetCompanyAllOptionsParamsDTO): Promise<CompanyAllDTO[]> {
		const url = this.basePath + `/`;
		const { data } = await this.getInstance().get<CompanyAllDTO[]>(url, { params });
		return data;
	}

	public async index(params: GetCompaniesRequestDTO): Promise<Company[]> {
		const { organization_id } = params;
		const url = this.basePath + `/user_list/${organization_id}`;
		const { data } = await this.getInstance().get<CompanyDTO[]>(url);
		return data;
	}

	public async findAllWorstScore(params: FindAllWorstScoreRequestDTO): Promise<Mosaic> {
		const url = this.basePath + '/worst-score';
		const { data } = await this.getInstance().get<CompanyMosaicDTO>(url, { params });
		return new CompanyMosaicMapper(data).toDomain();
	}
}
