import { Mapper } from '../base-mapper';
import { UpdateCommentResponseDTO, UpdateCommentItemDTO } from '@/core/dto';
import { ActionPlanUpdateComments, ActionPlanUpdateCommentsDomain } from '@/core/domain';

export class ActionPlanUpdateCommentsMapper implements Mapper<ActionPlanUpdateCommentsDomain> {
	private readonly response: UpdateCommentResponseDTO;

	constructor(response: UpdateCommentResponseDTO) {
		this.response = {
			status: response.status,
			message: response.message,
			data: Array.isArray(response.data) ? response.data : []
		};
	}

	private mapActionPlanItem(item: UpdateCommentItemDTO): ActionPlanUpdateComments {
		return new ActionPlanUpdateComments({
			id: item.id
		});
	}

	toDomain(): ActionPlanUpdateCommentsDomain {
		return {
			status: this.response.message,
			message: this.response.message,
			data: this.response.data.map((item) => this.mapActionPlanItem(item))
		};
	}
}
