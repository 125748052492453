export const breadcrumbNameMap = {
	'/ei': 'EI',
	'/ehs': 'EHS',
	'/files': 'Reports',
	'/upload': 'Upload',
	'/workers': 'Workers',
	'/ehs/files': 'Files',
	'/detailed': 'Detailed',
	'/settings': 'Settings',
	'/reporting': 'Reports',
	'/dashboard': 'Detailed',
	'/settings/plan': 'Plan',
	'/ehs/workers': 'Workers',
	'/engineering/mtm': 'MTM',
	'/ehs/detailed': 'Detailed',
	'/ehs/reporting': 'Reports',
	'/ehs/reporting/aet': 'AET',
	'/ehs/rotation': 'Rotation',
	'/engineering': 'Engineering',
	'/appointment': 'Appointment',
	'/action_plan': 'Action plans',
	'/ehs/complaints': 'Complaints',
	'/ehs/rotation/report': 'Report',
	'/health-matrix': 'Health Matrix',
	'/ehs/appointment': 'Appointment',
	'/settings/account': 'My account',
	'/settings/companies': 'Companies',
	'/ehs/action_plan': 'Action plans',
	'/ehs/action_plans/v2': 'Action plans',
	'/ehs/certificates': 'Certificates',
	'/ehs/health-matrix': 'Health Matrix',
	'/settings/preferences': 'Preferences',
	'/settings/information': 'Informations',
	'/settings/manage-users': 'Manage users',
	'/reporting/checklist-ei': 'Checklist EI',
	'/reporting/checklist-ehs': 'Checklist EHS',
	'/appointment_dashboard': 'New Appointment',
	'/ehs/reporting/checklist-ei': 'Checklist EI',
	'/ehs/reporting/checklist-ehs': 'Checklist EHS',
	'/engineering/chrono-analysis/report': 'Create',
	'/engineering/chrono-analysis': 'Chronoanalysis',
	'/ehs/complaints-certificates': 'Complaints list',
	'/complaints-certificates': 'Complaints and Certificates'
};
