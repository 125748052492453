import React from 'react';
import { Row, Col } from 'antd';
import { Text, Title } from '@/components/Typography';

interface InfoItemProps {
	title?: string;
	value?: string;
}

export function InfoItem({ title = '', value = '' }: Readonly<InfoItemProps>) {
	return (
		<Row>
			<Col>
				<Title level={5} style={{ fontSize: 16 }}>
					{title}
				</Title>
			</Col>
			<Col>:</Col>
			<Col>
				<Text ellipsis style={{ marginLeft: '5px', fontSize: 16 }}>
					{value}
				</Text>
			</Col>
		</Row>
	);
}
