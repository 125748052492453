import React, { createContext, useContext, useState } from 'react';
import type { ActionPlanHierarchy, ActionPlanTask, Context } from '../types';
import type { SelectOption } from '@/views/ActionPlan/types';

interface CreateActionPlanProviderProps {
	users?: SelectOption[];
	children: React.ReactNode;
	hierarchy: ActionPlanHierarchy;
}

const CreateActionPlanContext = createContext<Context>({} as Context);

export function CreateActionPlanProvider({ children, hierarchy, users }: Readonly<CreateActionPlanProviderProps>) {
	const [tasks, setTasks] = useState<ActionPlanTask[]>([]);

	function handleUpdateTasks(tasks: ActionPlanTask[]): void {
		setTasks(tasks);
	}

	const contextValues = {
		tasks,
		hierarchy,
		users: users ?? [],
		onUpdateTasks: handleUpdateTasks
	};

	return <CreateActionPlanContext.Provider value={contextValues}>{children}</CreateActionPlanContext.Provider>;
}

export function useCreateActionPlanContext() {
	const context = useContext(CreateActionPlanContext);
	return context;
}
