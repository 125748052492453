import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Typography } from 'antd';

import { Task } from './Task';
import { useGetFileActionPlans } from '@/hooks/v2/useGetFileActionPlan';
import { TableListDescription, TableDescription, DateComponent, RowCustomer, TableTitle } from './styles';
import moment from 'moment';

const { Title, Text } = Typography;

interface ActionPlansProps {
	file_id: string;
	company_id: string;
	organization_id: string;
}

export function ActionPlansCard({ file_id, company_id, organization_id }: ActionPlansProps) {
	const { data } = useGetFileActionPlans({ file_id, company_id, organization_id });

	if (!data?.length) {
		return null;
	}

	return (
		<Row gutter={[0, 30]} style={{ marginBottom: '3rem' }}>
			<Col span={24}>
				<Title level={4} style={{ marginBottom: -10 }}>
					{I18n.get('Action plans')}
				</Title>
			</Col>
			{data?.map(({ id, responsible_user, due_date, title, description, action_plan_tasks }) => {
				return (
					<Col key={id} span={24}>
						<RowCustomer justify="center">
							<Col span={24}>
								<Row justify="space-between">
									<TableTitle>
										<Title level={5}>{title}</Title>
									</TableTitle>
								</Row>
								<Row>
									<Col span={12}>
										<Row>
											<TableTitle span={24} $hasColor>
												<Text>{I18n.get('Responsible')}</Text>
											</TableTitle>
											<TableDescription>{responsible_user.name}</TableDescription>
										</Row>
										<Row>
											<TableTitle span={24} $hasColor>
												<Text>{I18n.get('Deadline')}</Text>
											</TableTitle>
											<TableDescription span={24}>
												<Row justify="space-between" align="middle">
													<Col xl={11} xxl={12}>
														{moment(due_date).diff(moment(), 'days')}{' '}
														<Text>
															{' '}
															{I18n.get(
																Math.abs(moment(due_date).diff(moment(), 'days')) <= 1
																	? 'day'
																	: 'days'
															)}
														</Text>
													</Col>
													<Col>
														<DateComponent>
															<Row>
																<Col>{moment(due_date, 'YYYY/MM/DD').format('DD')}</Col>
																<Col>{moment(due_date, 'YYYY/MM/DD').format('MM')}</Col>
																<Col>
																	{moment(due_date, 'YYYY/MM/DD').format('YYYY')}
																</Col>
															</Row>
														</DateComponent>
													</Col>
												</Row>
											</TableDescription>
										</Row>
									</Col>
									<Col span={12}>
										<Row>
											<TableTitle span={24} $hasColor $hasBorder>
												<Text>{I18n.get('Description')}</Text>
											</TableTitle>
										</Row>
										<Row style={{ height: '100%' }}>
											<TableDescription span={24} $customPadding="20px 38px" $hasBorder>
												{description}
											</TableDescription>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Row>
											<TableTitle $hasColor span={24}>
												<Text>{I18n.get('Tasks')}</Text>
											</TableTitle>
											<TableListDescription lg={24} xl={22}>
												{action_plan_tasks?.length > 0
													? action_plan_tasks?.map((task) => (
															<Task key={task.id} description={task.title} />
													  ))
													: 'N/A'}
											</TableListDescription>
										</Row>
									</Col>
								</Row>
							</Col>
						</RowCustomer>
					</Col>
				);
			})}
		</Row>
	);
}
