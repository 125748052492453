import { BaseService } from './base-service';
import { DashboardComparative } from '@/core/domain/dashboard';
import { DashboardComparativeMapper } from '@/core/mapper/dashboard-comparative';
import { DashboardComparativeDTO, GetComparativeRequestDTO } from '@/core/dto/dashboard';

export class DashboardService extends BaseService {
	private static instance: DashboardService;

	constructor(public readonly basePath: string = '/dashboard') {
		super();
	}

	public static getInstance(): DashboardService {
		if (!DashboardService.instance) {
			DashboardService.instance = new DashboardService();
		}
		return DashboardService.instance;
	}

	async getComparative(params: GetComparativeRequestDTO): Promise<DashboardComparative> {
		const { data } = await this.getInstance().get<DashboardComparativeDTO>(`${this.basePath}/consolidated-period`, {
			params
		});
		return new DashboardComparativeMapper(data).toDomain();
	}
}
