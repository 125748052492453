import React from 'react';
import { useSelector } from 'react-redux';
import { CreateActionPlan } from './CreateActionPlan';
import type { ChecklistState, HierarchyItem } from './types';
import * as S from './styles';

const MODAL_WIDTH = 700;

interface ModalActionPlanProps {
	visible: boolean;
	onClose: () => void;
}

type SelectorState = {
	checklist: ChecklistState;
	organization: {
		organization: HierarchyItem;
		company: HierarchyItem;
	};
};

export function ModalActionPlan({ visible, onClose }: ModalActionPlanProps) {
	const checklist = useSelector<SelectorState, ChecklistState>((state) => state.checklist);
	const company = useSelector<SelectorState, HierarchyItem>((state) => state.organization.company);
	const organization_id = useSelector<SelectorState, string>((state) => state.organization.organization.id);

	function onCloseModal() {
		onClose();
	}

	const hierarchy = {
		checklist_id: checklist.checklist.id,
		file_id: checklist.file.id,
		company_id: company.id,
		organization_id: organization_id,
	};

	return (
		<S.ModalWrapper width={MODAL_WIDTH} open={visible} onCancel={onCloseModal} footer={null}>
			<CreateActionPlan hierarchy={hierarchy} onClose={onClose}/>
		</S.ModalWrapper>
	);
}
