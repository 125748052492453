import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Row, message } from 'antd';
import { useCreateActionPlanContext } from './context';
import { useCreateActionPlanFromReport } from '@/hooks/v2/useCreateActionPlanFromReport';
import * as S from '../styles';

const { useFormInstance } = Form;

interface FooterProps {
	onClose(): void;
	formName: string[];
}

export function Footer({ formName, onClose }: Readonly<FooterProps>) {
	const form = useFormInstance();
	const { tasks, hierarchy } = useCreateActionPlanContext();

	const { isLoading, mutateAsync: createActionPlan } = useCreateActionPlanFromReport();

	async function handleOnSubmit(): Promise<void> {
		try {
			await form.validateFields();
			const values = form.getFieldValue(formName);

			const payload = {
				organization_id: hierarchy.organization_id,
				company_id: hierarchy.company_id,
				file_id: hierarchy.file_id,
				title: values.action_plan_name,
				due_date: values.due_date,
				description: values.description,
				responsible_user_id: values.responsible_id,
				investment_range: values.investment_range,
				priority: values.priority,
				checklist_id: hierarchy.checklist_id,
				tasks
			};

			await createActionPlan(payload);
			onClose();
		} catch (error: any) {
			if (!error?.errorFields) {
				const errorMessage = error?.response?.data.message || error.message;
				message.error(I18n.get(errorMessage));
			}
		}
	}

	return (
		<Row justify="center" gutter={[12, 0]}>
			<Col>
				<S.SecondaryButton onClick={onClose}>{I18n.get('Cancel')}</S.SecondaryButton>
			</Col>
			<Col>
				<S.PrimaryButton type="primary" loading={isLoading} onClick={handleOnSubmit}>
					{I18n.get('Save')}
				</S.PrimaryButton>
			</Col>
		</Row>
	);
}
